.hero{
    width: 100%;
    display: flex;
    align-items: center;

    padding-left: var(--horizontal-content-margin);
    padding-right: var(--horizontal-content-margin);
    margin-top: 22%;
}

.hero .information{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    background: var(--shade);
    /*box-shadow: 0 0 250px var(--shade);*/
}
.hero .information::before{
    content: "";
    position: absolute;
    top: -150px;
    left: 0;
    z-index: -1;
    height: 150px;
    width: 100%;
    background: linear-gradient(to bottom, #0b0e1100 0%, #0b0e11 90%);
    pointer-events: none;
}

.hero .information .request{
    pointer-events: all;
}
.hero .information .ghost{
    margin-bottom: 32px;
}
.hero .information .title{
    font-size: 64px;
}
.hero .information .subtitle{
    font-size: 18px;
    font-weight: 500;
    margin-top: 32px;
    max-width: 60%;

    color: var(--tint-2);
    line-height: 150%;
}
.hero .information .subtitle span{
    color: var(--tint);
}
.hero .information .request{
    display: flex;
    align-items: center;
    margin-top: 44px;
}
.hero .information .request input{
    margin-right: 8px;
}

.hero .background{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;

    width: 100%;
    height: 100%;

    overflow: clip;
}

.hero .background .data-line{
    position: absolute;
    top: 0;
    z-index: -2;
    width: 1.5px;
    height: 60%;
    overflow: clip;
    background: linear-gradient(to bottom, #26292E00 0%, #26292E66 20%, #26292E00 100%);
}
.hero .background .data-line .active{
    position: absolute;
    width: 100%;
    z-index: -2;
    height: 384px;
    background: linear-gradient(to bottom, #9D4EDD00 0%, #9D4EDD 30%, #9D4EDD 70%, #9D4EDD00 100%);
    animation: data-line-active .4s linear forwards;
    animation-duration: 2s;

    box-shadow: var(--purple-shadow);
}

@keyframes data-line-active{
    0%{
        top: -384px;
        opacity: 0;
    }
    50%{
        top: calc(50% - 384px);
        opacity: 1;
    }
    100%{
        top: calc(100% - 384px);
        opacity: 0;
    }
}

.hero .background img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.socials{
    margin: 196px 0;
}
.socials .social{
    position: relative;
    z-index: 0;

    width: 24px;
    height: 24px;
    margin-right: 32px;

    pointer-events: all;
    cursor: pointer;
}
.socials .social:last-child{
    margin-right: 0;
}
.socials .social svg{
    width: 100%;
    height: 100%;
    transform: translateZ(0);
}

.socials .social svg path{
    fill: var(--tint-2);
    transition: fill .2s ease;
}
.socials .social:hover svg path{
    fill: var(--tint);
}


@media only screen and (max-width: 1200px){
    .hero .information .subtitle{
        max-width: 100%;
    }
}

@media only screen and (max-width: 600px){
    .hero .information .request input{
        display: none;
    }
}

@media only screen and (max-width: 800px){
    .hero{
        margin-top: 40vh;
    }

    .hero .information .ghost{
        margin-bottom: 24px;
    }

    .hero .information .title{
        font-size: 36px;
    }
    .hero .information .title br{
        display: none;
    }
    .hero .information .subtitle{
        font-size: 14px;
        margin-top: 16px;
    }
    .hero .information .request{
        margin-top: 24px;
    }

    .socials{
        margin: 96px 0;
    }
    .socials .social{
        width: 20px;
        height: 20px;
        margin-right: 24px;
    }
}