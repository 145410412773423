.content-divider{
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
}
.content-divider::before{
    position: absolute;
    content: "";
    top: 50%;
    left: 25%;
    width: 50%;
    height: 2px;
    background: linear-gradient(to right, #26292ED1 30%, #26292E00 40%, #26292E00 60%, #26292ED1 70%);
}
.content-divider.wider::before{
    background: linear-gradient(to right, #26292ED1 20%, #26292E00 30%, #26292E00 70%, #26292ED1 80%);
}

@media only screen and (max-width: 1500px){
    .content-divider.animated.active::before{
        left: 10%;
        width: 80%;
    }
}

@media only screen and (max-width: 800px){
    .content-divider::before{
        background: linear-gradient(to right, #26292ED1 20%, #26292E00 30%, #26292E00 70%, #26292ED1 80%);
    }
    .content-divider.wider::before{
        background: linear-gradient(to right, #26292ED1 10%, #26292E00 20%, #26292E00 80%, #26292ED1 90%);
    }
}