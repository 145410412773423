.services{
    display: flex;
    padding: 0 var(--horizontal-content-margin);

    margin-top: 96px;
    justify-content: space-between;
}

.services .card{
    width: calc(33% - (96px / 3));
}
.services.top .card{
    transform: translateY(48px);
}
.services.top .card:nth-child(2){
    transform: none;
}

.services.bottom {
    margin-top: 0;
    justify-content: center;
}
.services.bottom .card:nth-child(1){
    margin-right: 48px;
}

.optionally-with{
    margin-top: calc(96px + 48px) !important;
}
.text-divider{
    margin-top: 96px;
    margin-bottom: 96px;
}
.text-divider p{
    font-size: 16px;
    font-weight: 500;
    color: var(--tint-2);
}

.services-subtitle{
    width: 100%;
    text-align: center;

    font-size: 24px;
    font-weight: 600;
    color: var(--tint);
}


@media only screen and (max-width: 1200px){
    .services{
        flex-wrap: wrap;
        justify-content: center;
        gap: 48px;
    }

    .services.top.animated.active .service-card, .services.bottom.animated.active .service-card{
        transform: translateY(0) !important;
    }
    .services .card{
        width: calc(50% - 24px);
        margin-right: 0 !important;
    }

    .optionally-with{
        margin-top: 96px !important;
    }
}

@media only screen and (max-width: 800px){
    .services{
        margin-top: 48px;
        gap: 24px;
    }
    .services .card{
        width: 100%;
    }

    .optionally-with{
        margin-top: 48px !important;
        margin-bottom: 48px;
    }

    .text-divider p{
        font-size: 14px;
        max-width: 60% !important;
        text-align: center;
    }
}