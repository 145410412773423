.default-text-container{
    margin-top: 196px;
    padding: 0 var(--horizontal-content-margin);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
}

.default-text-container h1{
    font-size: 40px;
}

.default-text-container button{
    margin-bottom: 24px;
}

.default-text-container h3{
    font-size: 20px;
    font-weight: 600;
    margin-top: 32px;
    margin-bottom: 16px;
}

.default-text-container > p{
    font-size: 16px;
    font-weight: 500;
    color: var(--tint-2);
    line-height: 150%;
}