.projects{
    display: flex;
    align-items: stretch;
    justify-content: center;

    margin-top: 96px;
    margin-bottom: 196px;
    padding: 0 calc(var(--horizontal-content-margin) * 1);
}

.project{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    padding: 48px;
    width: 36%;
}
.project:first-child{
    margin-right: 48px;
}

.project img{
    width: 80%;
    aspect-ratio: 1.25/1;
}
.project .information{
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin-top: 48px;
}

.project .information h5{
    font-size: 24px;
    font-weight: 600;
}
.project .information > p{
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    color: var(--tint-2);

    margin-top: 24px;
    margin-bottom: 32px;
}

.project .information > p span{
    color: var(--tint);
}


@media only screen and (max-width: 1200px){
    .project{
        width: 50%;
    }
}

@media only screen and (max-width: 800px){
    .projects{
        margin-top: 48px;
        margin-bottom: 96px;
        flex-direction: column;
        gap: 24px;
    }
    .project{
        width: 100%;
        padding: 40px 24px;
    }
    .project .information h5{
        font-size: 22px;
    }
    .project .information > p{
        margin-top: 16px;
        margin-bottom: 24px;
    }
}