/*Navbar*/
nav.animated{
    padding-top: var(--vertical-page-margin);
    padding-bottom: var(--vertical-page-margin);
    background: rgba(11, 14, 17, 0);
    backdrop-filter: blur(0px);

    transition: padding-top .4s cubic-bezier(.17, .67, .36, 1.16), padding-bottom .4s cubic-bezier(.17, .67, .36, 1.16), background .4s ease, backdrop-filter .4s ease;
}
nav.animated.active{
    padding-top: 24px;
    padding-bottom: 24px;
    background: rgba(11, 14, 17, 0.4);
    backdrop-filter: blur(5px);
}

/*Content-Divider*/
.content-divider.animated::before{
    opacity: 0;

    left: 50%;
    width: 0;

    transition: opacity .4s ease, width .4s cubic-bezier(.17, .67, .36, 1.16), left .4s cubic-bezier(.17, .67, .36, 1.16);
}
.content-divider.animated.active::before{
    opacity: 1;
    width: 50%;
    left: 25%;
}

.content-divider.animated *{
    opacity: 0;
    transition: opacity .2s .3s ease;
}
.content-divider.animated.active *{
    opacity: 1;
}

/*Title-Container*/
.title-container.animated button.ghost{
    opacity: 0;
    transform: translateY(48px);
    transition: opacity .4s ease, transform .4s cubic-bezier(.17, .67, .36, 1.16);
}
.title-container.animated.active button.ghost{
    opacity: 1;
    transform: translateY(0);
}

.title-container.animated h3{
    opacity: 0;
    transform: translateY(48px);
    transition: opacity .4s .2s ease, transform .4s .2s cubic-bezier(.17, .67, .36, 1.16);
}
.title-container.animated.active h3{
    opacity: 1;
    transform: translateY(0);
}

.title-container.animated p.subtitle{
    opacity: 0;
    transform: translateY(48px);
    transition: opacity .4s .4s ease, transform .4s .4s cubic-bezier(.17, .67, .36, 1.16);
}
.title-container.animated.active p.subtitle{
    opacity: 1;
    transform: translateY(0);
}

/*Hero*/
.hero .information.animated .ghost{
    opacity: 0;
    transform: translateY(48px);
    transition: opacity .4s ease, transform .4s cubic-bezier(.17, .67, .36, 1.16);
}
.hero .information.animated.active .ghost{
    opacity: 1;
    transform: translateY(0);
}

.hero .information.animated h1.title{
    transform: translateY(48px);
    opacity: 0;

    transition: transform .4s .2s cubic-bezier(.17, .67, .36, 1.16), opacity .4s .2s ease;
}
.hero .information.animated.active h1.title{
    transform: translateY(0);
    opacity: 1;
}

.hero .information.animated p.subtitle{
    transform: translateY(48px);
    opacity: 0;

    transition: transform .4s .4s cubic-bezier(.17, .67, .36, 1.16), opacity .4s .4s ease;
}
.hero .information.animated.active p.subtitle{
    transform: translateY(0);
    opacity: 1;
}

.hero .information.animated .request input{
    opacity: 0;
    transform: translateX(-48px);
    transition: opacity .4s .5s ease, transform .4s .5s cubic-bezier(.17, .67, .36, 1.16);
}
.hero .information.animated.active .request input{
    opacity: 1;
    transform: translateX(0);
}

.hero .information.animated .request button.primary{
    opacity: 0;
    transform: translateX(48px);
    transition: opacity .4s .6s ease, transform .4s .6s cubic-bezier(.17, .67, .36, 1.16);
}
.hero .information.animated.active .request button.primary{
    opacity: 1;
    transform: translateX(0);
}

/*Top*/
.services.top.animated .service-card:nth-child(2){
    opacity: 0;
    transform: translateY(48px);
    transition: opacity .4s ease, transform .4s cubic-bezier(.17, .67, .36, 1.16);
}
.services.top.animated.active .service-card:nth-child(2){
    opacity: 1;
    transform: translateY(0);
}

.services.top.animated .service-card:nth-child(1){
    opacity: 0;
    transform: translateY(96px);
    transition: opacity .4s .1s ease, transform .4s .1s cubic-bezier(.17, .67, .36, 1.16);
}
.services.top.animated.active .service-card:nth-child(1){
    opacity: 1;
    transform: translateY(48px);
}

.services.top.animated .service-card:nth-child(3){
    opacity: 0;
    transform: translateY(96px);
    transition: opacity .4s .2s ease, transform .4s .2s cubic-bezier(.17, .67, .36, 1.16);
}
.services.top.animated.active .service-card:nth-child(3){
    opacity: 1;
    transform: translateY(48px);
}

/*Bottom*/
.services.bottom.animated .service-card:nth-child(1){
    opacity: 0;
    transform: translateY(48px);
    transition: opacity .4s ease, transform .4s cubic-bezier(.17, .67, .36, 1.16);
}
.services.bottom.animated.active .service-card:nth-child(1){
    opacity: 1;
    transform: translateY(0px);
}
.services.bottom.animated .service-card:nth-child(2){
    opacity: 0;
    transform: translateY(48px);
    transition: opacity .4s .1s ease, transform .4s .1s cubic-bezier(.17, .67, .36, 1.16);
}
.services.bottom.animated.active .service-card:nth-child(2){
    opacity: 1;
    transform: translateY(0px);
}

/*Schema*/
.schema.animated .title-container h3{
    opacity: 0;
    transform: translateY(48px);
    transition: opacity .4s ease, transform .4s cubic-bezier(.17, .67, .36, 1.16);
}
.schema.animated.active .title-container h3{
    opacity: 1;
    transform: translateY(0);
}

.schema.animated .title-container p.subtitle{
    opacity: 0;
    transform: translateY(48px);
    transition: opacity .4s .2s ease, transform .4s .2s cubic-bezier(.17, .67, .36, 1.16);
}
.schema.animated.active .title-container p.subtitle{
    opacity: 1;
    transform: translateY(0);
}

.schema.animated .steps .step{
    opacity: 0;
    transform: translateY(48px);
    transition: opacity .4s ease, transform .4s cubic-bezier(.17, .67, .36, 1.16);
}
.schema.animated.active .steps .step{
    opacity: 1;
    transform: translateY(0);
}
.schema.animated .steps .connector{
    opacity: 0;
    height: 0;
    transition: opacity .4s ease, height .4s cubic-bezier(.17, .67, .36, 1.16);
}
.schema.animated.active .steps .connector{
    opacity: 1;
    height: 150%;
}

/*Advantages*/
.advantages.animated .advantage{
    opacity: 0;
    transform: translateY(48px);
    transition: opacity .4s ease, transform .4s cubic-bezier(.17, .67, .36, 1.16);
}
.advantages.animated .advantage:nth-child(even){
    transform: translateY(96px);
}
.advantages.animated.active .advantage{
    opacity: 1;
    transform: translateY(0);
}
.advantages.animated.active .advantage:nth-child(even){
    transform: translateY(48px);
}

/*Projects*/
.projects.animated .project{
    opacity: 0;
    transform: translateY(48px);
    transition: opacity .4s ease, transform .4s cubic-bezier(.17, .67, .36, 1.16);
}
.projects.animated.active .project{
    opacity: 1;
    transform: translateY(0);
}

/*Employees*/
.employee-carousel.animated .employee img{
    opacity: 0;
    transform: translateY(48px);
    transition: opacity .4s ease, transform .4s cubic-bezier(.17, .67, .36, 1.16);
}
.employee-carousel.animated.active .employee img{
    opacity: 1;
    transform: translateY(0);
}

.employee-carousel.animated .employee h5{
    opacity: 0;
    transform: translateY(48px);
    transition: opacity .4s .2s ease, transform .4s .2s cubic-bezier(.17, .67, .36, 1.16);
}
.employee-carousel.animated.active .employee h5{
    opacity: 1;
    transform: translateY(0);
}

.employee-carousel.animated .employee p{
    opacity: 0;
    transform: translateY(48px);
    transition: opacity .4s .4s ease, transform .4s .4s cubic-bezier(.17, .67, .36, 1.16);
}
.employee-carousel.animated.active .employee p{
    opacity: 1;
    transform: translateY(0);
}

.employee-carousel.animated .employee button{
    opacity: 0;
    transform: translateY(48px);
    transition: opacity .4s .6s ease, transform .4s .6s cubic-bezier(.17, .67, .36, 1.16);
}
.employee-carousel.animated.active .employee button{
    opacity: 1;
    transform: translateY(0);
}


.employee-carousel.animated .employee-placeholder{
    opacity: 0;
    transform: translateY(calc(50% + 48px));
    transition: opacity .4s ease, transform .4s cubic-bezier(.17, .67, .36, 1.16);
}
.employee-carousel.animated.active .employee-placeholder{
    opacity: 1;
    transform: translateY(50%);
}

.employee-carousel.animated .employee-placeholder:nth-child(2), .employee-carousel.animated .employee-placeholder:nth-child(4){
    transition-delay: .8s;
}
.employee-carousel.animated .employee-placeholder:nth-child(1), .employee-carousel.animated .employee-placeholder:nth-child(5){
    transition-delay: 1s;
}