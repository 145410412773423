nav{
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: var(--vertical-page-margin) var(--horizontal-page-margin);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;

    backdrop-filter: blur(5px);
    width: 100%;

    transition: padding .1s ease, background .1s ease;
}

nav .navigation{
    display: flex;
    align-items: center;
}
nav .navigation a{
    display: flex;
    align-items: center;
}
nav .navigation a img{
    width: 28px;
    height: 28px;

    margin-right: 12px;
}
nav .navigation a h2{
    font-size: 24px;
    font-weight: 700;
    /*transform: translateY(-2px);*/
}

nav .navigation .items{
    display: flex;
    align-items: center;
    margin-right: var(--horizontal-page-margin);
    margin-left: 24px;
}
nav .navigation .items .item{
    padding: 0 16px;
}

nav .ctas .clear{
    padding: 0 16px;
    margin-right: 16px;
}


@media only screen and (max-width: 900px){
    nav button.clear{
        display: none;
    }
}