.advantages{
    display: flex;

    margin-top: 96px;
    padding: 0 var(--horizontal-content-margin);

    margin-bottom: calc(196px + 48px);
}

.advantage{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    padding: 48px 28px;
    margin-right: 24px;
}
.advantage:last-child{
    margin-right: 0;
}

.advantage:nth-child(even){
    transform: translateY(48px);
}

.advantage img{
    max-width: 256px;
    aspect-ratio: 2/1;
    object-fit: contain;
    margin-bottom: 32px;
}

.advantage h5{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 24px;
}
.advantage p{
    font-size: 14px;
    font-weight: 400;
    color: var(--tint-2);
    line-height: 150%;
}


@media only screen and (max-width: 1200px){
    .advantages{
        flex-wrap: wrap;
        gap: 24px;
        justify-content: center !important;
        margin-bottom: 196px;
    }

    .advantages .advantage{
        width: calc(33% - 24px);
        margin-right: 0;
    }
    .advantages.animated.active .advantage{
        transform: translateY(0) !important;
    }
}

@media only screen and (max-width: 800px){
    .advantages{
        margin-top: 48px;
        gap: 16px;

        margin-bottom: 96px;
    }
    .advantages .advantage{
        flex-direction: row;
        align-items: center;
        width: 100%;
        text-align: left;
        padding: 24px;
    }
    .advantages .advantage img{
        width: 64px !important;
        min-width: 64px !important;
        height: 64px !important;
        aspect-ratio: auto !important;
        object-fit: contain;
        margin-bottom: 0;
        margin-right: 16px;
    }
    .advantages .advantage:nth-child(1) img{
        transform: scale(.7);
    }
    .advantages .advantage:nth-child(2) img{
        transform: scale(1.3);
    }
    .advantages .advantage h5{
        font-size: 15px;
        margin-bottom: 8px;
    }
    .advantages .advantage p{
        font-size: 12px;
    }
}