.request-dialog{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
}

.request-dialog .background{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(25px);
}

.request-dialog .content-container{
    width: 100%;
    padding: 0 var(--horizontal-content-margin);
    display: flex;
    align-items: center;
    justify-content: center;
}

.request-dialog .container{
    width: 60%;
}
.request-dialog form{
    pointer-events: none;
    position: relative;
    z-index: 6;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.request-dialog form *{
    pointer-events: all;
}

.request-dialog form button.primary[disabled]{
    background: var(--shade-2);
    border-color: var(--shade-3);
    box-shadow: none;
    cursor: not-allowed;
}
.request-dialog.active .container form > button.primary[disabled]{
    opacity: .6;
}

.request-dialog.active .container form:has(button.primary.submitting) > *:not(button.primary){
    opacity: .4 !important;
    cursor: not-allowed;
}

.request-dialog .container .sent{
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    text-align: center;
    z-index: 5;
}

.request-dialog .container .sent h3{
    font-size: 48px;
}
.request-dialog .container .sent > p{
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    color: var(--tint-2);
    margin-top: 24px;
    margin-bottom: 32px;
}
.request-dialog .container .sent > p span{
    color: var(--tint);
}

.request-dialog .container .row{
    width: 100%;
}
.request-dialog .container .row input:first-child{
    margin-right: 16px;
}
.request-dialog .container input{
    min-width: 128px;
    width: 100%;
    margin-bottom: 16px;
}
.request-dialog .container textarea{
    min-width: 256px;
    width: 100%;
    min-height: 256px;
    margin-bottom: 24px;
}
.request-dialog .container form button{
    width: 100%;
}

.request-dialog .background{
    opacity: 0;
    transition: opacity .4s ease;
}
.request-dialog.active .background{
    opacity: 1;
}

.request-dialog .container .row{
    opacity: 0;
    transform: translateY(48px);
    transition: opacity .4s .2s ease, transform .4s .2s cubic-bezier(.17, .67, .36, 1.16);
}
.request-dialog.active .container .row{
    opacity: 1;
    transform: translateY(0);
}

.request-dialog .container form > input{
    opacity: 0;
    transform: translateY(48px);
    transition: opacity .4s .4s ease, transform .4s .4s cubic-bezier(.17, .67, .36, 1.16);
}
.request-dialog.active .container form > input{
    opacity: 1;
    transform: translateY(0);
}

.request-dialog .container form > textarea{
    opacity: 0;
    transform: translateY(48px);
    transition: opacity .4s .6s ease, transform .4s .6s cubic-bezier(.17, .67, .36, 1.16);
}
.request-dialog.active .container form > textarea{
    opacity: 1;
    transform: translateY(0);
}

.request-dialog .container form > button.primary{
    opacity: 0;
    transform: translateY(48px);
    transition: opacity .4s .8s ease, transform .4s .8s cubic-bezier(.17, .67, .36, 1.16);
}
.request-dialog.active .container form > button.primary{
    opacity: 1;
    transform: translateY(0);
}


@media only screen and (max-width: 1000px){
    .request-dialog .container{
        width: 100%;
    }
}