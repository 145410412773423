.service-card{
    padding: 48px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.service-card img{
    width: 80%;
    height: 80%;
}

.service-card h4{
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-top: 32px;
}

.service-card p{
    font-size: 14px;
    font-weight: 400;
    color: var(--tint-2);
    text-align: center;
    margin-top: 32px;
    margin-bottom: 16px;
    line-height: 150%;
}

.service-card button.ghost{
    margin-top: 32px;
    margin-bottom: -8px;
}


@media only screen and (max-width: 800px){
    .service-card{
        padding: 40px 24px;
    }
    .service-card h4{
        font-size: 22px;
    }
    .service-card p{
        margin-top: 16px;
        margin-bottom: 0;
    }
}