.employee-carousel{
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    margin-top: 96px;

    overflow: hidden;
}
.employee-carousel::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: linear-gradient(to right, #0B0E11 0%, #0B0E1100 45%, #0B0E1100 55%, #0B0E11 100%);
    pointer-events: none;
}

.employee{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.employee img{
    width: 216px;
    height: 216px;
    border-radius: 999px;

    margin-bottom: 48px;
}
.employee h5{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
}
.employee > p{
    font-size: 14px;
    font-weight: 500;
    color: var(--tint-2);
    line-height: 150%;
    max-width: 384px;
    text-align: center;
    margin-bottom: 48px;
}

.employee-placeholder{
    width: 160px;
    height: 160px;
    aspect-ratio: 1/1;
    background: var(--shade-2);
    border: 2px solid var(--shade-3);
    border-radius: 999px;

    margin: 0 48px;
    transform: translateY(50%);
}


@media only screen and (max-width: 1400px){
    .employee > p{
        min-width: 384px;
    }
}

@media only screen and (max-width: 800px){
    .employee-carousel{
        margin-top: 48px;
    }

    .employee img{
        width: 176px;
        height: 176px;
        margin-bottom: 28px;
    }
    .employee h5{
        font-size: 22px;
        margin-bottom: 20px;
    }
    .employee > p{
        margin-bottom: 32px;
    }
}