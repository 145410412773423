footer{
    margin-top: 196px;
    border-top: 2px solid var(--shade-2);

    padding: 72px var(--horizontal-content-margin);
}

footer .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

footer .top a{
    display: flex;
    align-items: center;
}
footer .top a img{
    width: 24px;
    height: 24px;
    margin-right: 12px;
}
footer .top a h2{
    font-size: 20px;
}

footer .top .inline-socials{
    display: flex;
    align-items: center;
}

footer .top .inline-socials svg{
    width: 20px;
    height: 20px;
    fill: var(--tint-2);
    margin-left: 24px;
    cursor: pointer;
    transition: fill .2s ease;
}
footer .top .inline-socials svg:hover{
    fill: var(--tint);
}

footer .bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
}
footer .bottom .item{
    margin-right: 48px;
}
footer .bottom .item p{
    opacity: .6;
}
footer .bottom .item:last-child{
    margin-right: 0;
}