.title-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 var(--horizontal-content-margin);
}

@media only screen and (min-width: 800px){
    .title-container.left{
        align-items: flex-start;
    }
    .title-container.left p.subtitle{
        text-align: left;
        max-width: none;
    }
}

.title-container .ghost{
    margin-bottom: 32px;
}

.title-container h3{
    font-size: 40px;
    text-align: center;
}

.title-container p.subtitle{
    font-size: 18px;
    font-weight: 500;
    color: var(--tint-2);
    margin-top: 32px;
    max-width: 50%;
    text-align: center;
    line-height: 150%;
}
.title-container p.subtitle span{
    color: var(--tint);
}


@media only screen and (max-width: 1200px){
    .title-container p.subtitle{
        max-width: 90%;
    }
}

@media only screen and (max-width: 800px){
    .title-container h3 {
        font-size: 28px;
    }
    .title-container p.subtitle{
        font-size: 14px;
        margin-top: 16px;
    }
}