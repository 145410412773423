.schema{
    margin-top: 128px;
    margin-bottom: 128px;
    padding: 0 var(--horizontal-content-margin);

    display: flex;
    align-items: center;
    width: 100%;
}

.schema > *{
    width: 50%;
}

.schema .title-container{
    padding-right: 20%;
}
.schema .title-container h3{
    text-align: left;
}

.schema .steps{
    display: flex;
    flex-direction: column;
}

.schema .steps{
    position: relative;
    max-height: 384px;
    overflow: hidden;
    transition: max-height .4s cubic-bezier(.17, .67, .36, 1.16);

    cursor: pointer;
}

.schema .steps::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 15%;
    background: linear-gradient(to bottom, #0B0E1100, var(--shade));
    transition: opacity .5s ease;
}
.schema .steps.expanded::after{
    opacity: 0;
}

.schema .steps.expanded{
    max-height: 640px;
}

.schema .steps .step{
    width: 100%;
    display: flex;
    align-items: center;
}
.schema .steps .step:last-child{
    margin-bottom: 0;
}

.schema .steps .step .number{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--shade-2);
    border-radius: 999px;
    border: 2px solid var(--shade-3);
    margin-right: 24px;
}
.schema .steps .step .number p{
    font-size: 14px;
    font-weight: 500;
    color: var(--tint);
}
.schema .steps .step .container{
    flex: 1;
    padding: 20px 24px;
    background: var(--shade-2);
    border: 2px solid var(--shade-3);
    border-radius: 12px;
}

.schema .steps .step h5{
    font-size: 16px;
    font-weight: 600;
    color: var(--tint);
}

.schema .steps .connector{
    height: 150%;
    min-height: 40px;
    width: 2px;
    background: linear-gradient(to bottom, #31343900 0%, #313439 20%, #313439 80%, #31343900 100%);
    border-radius: 999px;
    opacity: .6;
    margin-left: 19px;
}


@media only screen and (max-width: 1200px){
    .schema .title-container{
        padding-right: 10%;
    }
}

@media only screen and (max-width: 800px){
    .schema{
        flex-direction: column;
        margin-top: 96px;
        margin-bottom: 96px;
    }
    .schema > *{
        width: 100%;
    }
    .schema .title-container.left{
        padding: 0;
        margin-bottom: 48px;
    }

    .schema .steps{
        max-height: 218px;
    }

    .schema .steps .connector{
        min-height: 16px;
    }

    .schema .steps .step .container{
        padding: 12px 20px;
    }
    .schema .steps .step h5{
        font-size: 14px;
    }

    .schema .steps .step .number{
        margin-right: 16px;
    }
}