/*Support*/
@import url("./animations.css");

/*Fonts*/
@font-face {
  font-family: hg;
  src: url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap');
}

/*Colors*/
:root{
  --shade: #0b0e11;
  --shade-2: #26292ED1;
  --shade-3: #313439;

  --tint: #F7F7F7;
  --tint-2: #8B8C8E;

  --purple: #9D4EDD;
  --purple-40: #9D4EDD66;
  --purple-lg: linear-gradient(to bottom right, #7F31CE, #7333B4);
  --purple-shadow: 2px 5px 25px #7F31CE40;

  --green: #4EDD80;
  --green-40: #4EDD8066;
  --green-lg: linear-gradient(to bottom right, #269E50, #228444);
  --green-shadow: 2px 5px 25px #269E5040;

  --light-blue: #46ABCB;
  --light-blue-40: #46ABCB66;
  --light-blue-lg: linear-gradient(to bottom right, #3EA1C0, #2D85A1);
  --light-blue-shadow: 2px 5px 25px #3EA1C040;
}

/*Spacing*/
:root{
  --text-64: 64px;
  --text-26: 26px;
  --text-20: 20px;
  --text-18: 18px;

  --horizontal-page-margin: 48px;
  --vertical-page-margin: 32px;

  --element-height: 44px;

  --border-radius: 10px;

  --horizontal-content-margin: 48px;

  --max-content-width: 1600px;
}

/*General Styling*/
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: hg, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--shade);
  color: var(--tint);
}
a{
  text-decoration: none;
  color: inherit;
}
button, input, textarea{
  outline: none;
  border: none;
  background: none;
}
button{
  cursor: pointer;
}

/*Button Styles*/
button{
  font-size: 15px;
  font-weight: 500;
  color: var(--tint);
  height: var(--element-height);
}
button.clear{
  opacity: .6;
  transition: opacity .2s ease;
}
button.clear:hover{
  opacity: 1;
}
button.clear:active{
  opacity: .6;
}

button.primary{
  background: var(--purple-lg);
  padding: 0 16px;
  border-radius: var(--border-radius);
  border: 2px solid var(--purple-40);
  box-shadow: var(--purple-shadow);
  font-weight: 600;
  transition: transform .2s ease;
}
button.primary:hover{
  transform: scale(.98);
}
button.primary:active{
  transform: scale(.96);
}

button.ghost{
  height: auto;
  padding: 8px 16px;
  border-radius: 99px;
  font-size: 14px;
  font-weight: 600;
  color: var(--tint);
  border: 2px solid var(--purple);
}

.green button.ghost{
  border-color: var(--green);
}
button.primary.green{
  background: var(--green-lg);
  border-color: var(--green-40);
  box-shadow: none;
}

button.round{
  border-radius: 99px;
}

.blue button.ghost{
  border-color: var(--light-blue);
}
button.primary.blue{
  background: var(--light-blue-lg);
  border-color: var(--light-blue-40);
  box-shadow: var(--light-blue-shadow);
}

/*Input Styles*/
input, textarea{
  height: var(--element-height);
  padding: 0 16px;
  background: var(--shade-2);
  min-width: 336px;

  font-size: 15px;
  font-weight: 500;
  border-radius: var(--border-radius);
  border: 2px solid var(--shade-3);

  color: var(--tint);
}
textarea{
  font-family: hg, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  padding: 12px 16px;
}
::placeholder{
  color: var(--tint-2);
}

/*Library*/
.spacer{
  min-height: 512px;
}

.card{
  width: 100%;

  background: var(--shade-2);
  border-radius: var(--border-radius);
  border: 2px solid var(--shade-3);
}

.row{
  display: flex;
  align-items: center;
}

p.center-small{
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: var(--tint-2);

  margin-top: 96px;
  margin-bottom: 128px;
}

.content-container{
  max-width: var(--max-content-width);
  margin: 0 auto;
}

/*MediaQueries*/
@media only screen and (max-width: 800px){
  :root{
    --horizontal-content-margin: 24px;
    --horizontal-page-margin: 24px;
  }
}